<template>
  <div class="container">
    <div class="content">
      <div class="menu-card">
<!--        <div class="menu">-->
<!--          <div class="menu-link" @click="checkBindSteam('BindEmail', '绑定邮箱')">-->
<!--            <div class="menu-label">邮箱</div>-->
<!--            <div class="menu-value" :class="{ 'bind-btn': playerExtend && !playerExtend.email }">-->
<!--              {{ playerExtend && playerExtend.email ? playerExtend.email : '立即绑定' }}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="menu">-->
<!--          <div class="menu-link" @click="checkBindSteam('BindMobile', '绑定手机号')">-->
<!--            <div class="menu-label">手机号</div>-->
<!--            <div class="menu-value" :class="{ 'bind-btn': playerExtend && !playerExtend.mobile }">-->
<!--              {{ playerExtend && playerExtend.mobile ? playerExtend.mobile : '立即绑定' }}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="menu">
          <div class="menu-link" @click="handleBindSteam()">
            <div class="menu-label">SteamID</div>
            <div class="menu-value" :class="{ 'bind-btn': player && !player.steam_account }">
              {{ player && player.steam_account ? player.steam_account : '立即绑定' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UserSetting',
}
</script>
<script setup>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { bindSteamConfirm } from '@/utils/user.js'
import tool from '@/utils/tool'

const store = useStore()
const router = useRouter()
const playerExtend = computed(() => store.state.player.extend)
const player = computed(() => store.state.player.info)

onMounted(() => {
  store.dispatch('user/getInfo')
  store.dispatch('player/getInfo')
})

const user = computed(() => store.state.user.info)
const checkBindSteam = (name, title) => {
  if (user.value.player_id) {
    tool.data.set('setFromRoute', true)
    router.push({ name })
  } else {
    bindSteamConfirm(title)
  }
}

const handleBindSteam = () => {
  tool.data.set('setFromRoute', true)
  if (user.value && user.value.player_id) {
    router.push({ name: 'UnBindSteam' })
  } else {
    router.push({ name: 'BindSteam' })
  }
}
</script>
<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  color: var(--app-menu-color);
  display: flex;
  flex-flow: column;
  padding: 15px;
  top: 20px;
  border-radius: 10px 10px 0 0;
}

.menu-card {
  box-sizing: border-box;
  width: 100%;
  background: var(--app-main-bg-color);
  border-radius: 5px;
  margin-bottom: 15px;
  display: flex;
  flex-flow: column;
  padding: 0 20px;
  height: auto;
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu:after {
  font-family: 'vant-icon';
  content: '\e660';
  font-weight: 600;
  margin-left: 5px;
  width: 14px;
  height: 14px;
}

.menu-card .menu:not(:last-child) {
  border-bottom: 1px solid var(--app-border-bottom-color);
}

.menu-link {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

.menu-label {
  font-size: 14px;
  font-weight: 500;
  flex-shrink: 0;
}

.menu-value {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
}

.bind-btn {
  color: var(--app-active-color);
}
</style>
